<!-- 快速导入产品 -->
<template>
  <a-modal
        :width="1100"
    v-model:visible="visible"
    :title="$t('warehouse.quick_import')"
    :mask-closable="false"
    :centered="true"
    @ok="handleConfirm"
  >
    <a-table
      size="small"
      :columns="columns"
      :data-source="boxList"
      :pagination="false"
      :row-key="(record) => record.rowKey"
    >
      <template #title>
        <a-row :gutter="16">
          <a-col>
            <a-upload
              name="template"
              list-type="text"
              accept=".xlsx"
              :show-upload-list="false"
              :customRequest="handleUpload"
            >
              <a-button>
                <loading-outlined v-if="uploadLoading"></loading-outlined>
                <upload-outlined v-else></upload-outlined>
                {{ $t("warehouse.select_import_file") }}
              </a-button>
            </a-upload>
          </a-col>
          <a-col>
            <a-button type="link" :loading="downloadLoading" @click="handleDownloadExcel">{{ $t('warehouse.click_download_template') }}</a-button>
          </a-col>
        </a-row>
      </template>
      <template #serialNumber="{ index }">
        {{ index + 1 }}
      </template>
      <template #selfBoxNo="{ record }">
        {{ record.selfBoxNo }}
      </template>
      <template #encasementSize="{ record }">
        {{ record.containerLength || 0 }}x{{ record.containerWidth || 0 }}x{{ record.containerHeight || 0 }}cm
      </template>
      <template #encasementWeight="{ record }">
        {{ gToKg(record.containerWeight) }} kg
      </template>
      <template #containerCount="{ record }">
        {{ record.containerCount || 0 }} Unit
      </template>
      <template #error="{ record }">
        <a-popover v-if="!record.isNormal">
          <template #content>
            <div v-for="(item, index) in record.errObjs" :key="index">{{ item.msg }}</div>
          </template>
          <WarningOutlined class="text-error" style="font-size: 20px;" />
        </a-popover>
        <a-popover v-else-if="record?.productIsError">
          <template #content>
            {{$t('warehouse.product_import_error_prompt')}}
          </template>
          <WarningOutlined class="text-error" style="font-size: 20px;" />
        </a-popover>
      </template>
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>{{ $t('warehouse.when_you_confirm_the_import_only_the_correct_product_data_will_be_imported') }}</a-col>
        </a-row>
      </template>
      <template #expandedRowRender="{ record }">
        <a-table
          :columns="innerColumns"
          :data-source="record.items"
          :pagination="false"
          :row-key="(record) => record.rowKey"
          size="small"
        >
          <template #productInfo="{ record }">
            <a-row :gutter="8" type="flex" align="middle">
              <a-col :span="4">
                <div class="table-list-img-common">
                  <c-image
                    :src="record.imgUrl"
                    :thumbWidth="600"
                    :thumbHeight="600"
                  />
                </div>
              </a-col>
              <a-col :span="20">
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </a-col>
            </a-row>
          </template>
          <template #containerCount="{ record }">
            {{ record.containerCount || 0 }} Unit
          </template>
          <template #error="{ record }">
            <a-popover v-if="!record.isNormal">
              <template #content>
                <div v-for="(item, index) in record.errObjs" :key="index">{{ item.msg }}</div>
              </template>
              <WarningOutlined class="text-error" style="font-size: 20px;" />
            </a-popover>
          </template>
        </a-table>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Button, Col, Modal, Popover, Row, Table, Upload, message } from 'ant-design-vue';
import CImage from '@/views/components/CImage.vue';
import { useI18n } from "vue-i18n/index";
import { downloadFile } from '@/utils/downloader.js';
import { getGenerateTemplate, uploadParseExcelData } from '@/api/modules/consignment/inbound.js';
import { gToKg, dateString, randomString } from '@/utils/general.js';
import { maxUploadFileSizeByte } from '../../../../utils/common';

export default defineComponent({
  expose: ['open'],
  emits: ['confirm'],
  components: {
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    AUpload: Upload,
    AButton: Button,
    ATable: Table,
    APopover: Popover,
    CImage,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    
    const state = reactive({
      visible: false,
      uploadLoading: false,
      downloadLoading: false,
      boxList: [],
    });

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber"
        }
      },
      {
        width: 250,
        title: () => vueI18n.t("common.user_defined_box_no"),
        slots: {
          customRender: "selfBoxNo"
        }
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 100,
        slots: {
          customRender: "error"
        }
      },
    ];

    const innerColumns = [
      {
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        },
        width: 300,
      },
      {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 100,
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 100,
        slots: {
          customRender: "error"
        }
      },
    ];

    const handleUpload = (file) => {
      if (file?.file?.size > maxUploadFileSizeByte() ) {
        message.error(vueI18n.t('common.please_upload_file_with_size_less_than_50MB'));
        return
      }

      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      uploadParseExcelData(parameter).then(({ result }) => {
        if (Array.isArray(result)) {
          let boxList = result;
          state.boxList = boxList.map(item => {
            item.rowKey = randomString(12);
            item.items.map(ite => {
              ite.rowKey = randomString(12);
              if (!ite.isNormal) {
                item.productIsError = true;
              }
              return ite;
            });
            return item;
          })
        } else {
          state.boxList = []
        }
      }).catch(() => {}).finally(() => {
        state.uploadLoading = false;
      })
    }

    const handleDownloadExcel = () => {
      state.downloadLoading = true
      let url = getGenerateTemplate()
      downloadFile(url, `import_products_${dateString()}.xlsx`, "GET")
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          state.downloadLoading = false
        })
    }

    const handleConfirm = () => {
      let boxList = state.boxList.map(item => {
        item.containerWeight = item.containerWeight ? gToKg(item.containerWeight) : null;
        item.items = item.items.map(ite => {
          ite.id = ite.productId;
          if (!ite.errObjs || !ite.errObjs.length) {
            return ite;
          }
        }).filter(Boolean);
        return item;
      });
      let list = boxList.filter( x => x.isNormal && !x.productIsError);
      if (list.length > 0) {
        emit('confirm', list);
        close();
      } else {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.box_list')]));
      }
    }

    const open = () => {
      state.visible = true;
      state.boxList = [];
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      columns,
      innerColumns,
      gToKg,
      handleUpload,
      handleDownloadExcel,
      handleConfirm,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>