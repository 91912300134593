<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.storage_inbound_create') }}
    </template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <StepsRow :current="0" />
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
          :label-col="{ style: { width: '200px' } }"
        >
          <a-form-item :label="$t('warehouse.in_plan_name')" name="planName">
            <a-input v-model:value="formState.planName" :maxlength="128" style="width: 250px;" allow-clear></a-input>
          </a-form-item>
          <a-form-item :label="$t('warehouse.warehouse_type')">
            {{ $t($enumLangkey('warehouseType', formState.to.warehouseType)) }}
          </a-form-item>
          <a-form-item :label="$t('warehouse.inbound_target_warehouse')" :name="['to', 'warehouseId']">
            <a-select
              style="width: 250px;"
              v-model:value="formState.to.warehouseId"
              :loading="warehouseLoading"
              :placeholder="$t('warehouse.inbound_target_warehouse')"
              optionFilterProp="search-key"
              show-search
              allow-clear
              @change="handleWarehouseChange()"
            >
              <a-select-option
                v-for="(item, index) in warehouseList"
                :key="index"
                :search-key="item.warehouseNo + item.warehouseName"
                :title="item.warehouseNo + '('+item.warehouseName + ')'"
                :value="item.id"
              >{{ item.warehouseNo+'(' + item.warehouseName + ')' }}</a-select-option>
            </a-select>
            <a-tag v-if="showWarehouseTag" color="warning" class="ml-3">{{$t('logistics.support_distribution_warehouse')}}</a-tag>
          </a-form-item>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-form-item :label="$t('warehouse.warehouse_address')">
                {{ getSelectWarehouseAddress }}
              </a-form-item>
            </a-col>
            <a-col v-if="formState.to.warehouseId">
              <a-form-item :label="$t('logistics.contacts')">
                {{ linkMan }}
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <a-table
          style="overflow-x: auto;"
          class="mt-3 mb-4"
          size="small"
          :columns="columns"
          :data-source="boxList"
          :pagination="pagination"
          :row-key="(record) => record.rowKey"
          v-model:expanded-row-keys="boxExpandedRowKeysList"
          :expandIconAsCell="false"
          :expandIconColumnIndex="2"
          @change="(page) => pagination.current = page.current"
        >
          <template #title>
            <a-row :gutter="16">
              <a-col><a-button type="primary" ghost @click="handleOpenProductModal(null, true)">{{ $t('warehouse.add_by_the_product') }}</a-button></a-col>
              <a-col><a-button type="primary" ghost @click="handleAddBox()">{{ $t('warehouse.add_box') }}</a-button></a-col>
              <a-col><a-button type="primary" ghost @click="handleOpenImportModal()">{{ $t('warehouse.quick_import') }}</a-button></a-col>
            </a-row>
          </template>
          <template #serialNumber="{ index }">
            {{ ((pagination.current - 1) * pagination.pageSize) + (index + 1) }}
          </template>
          <template #selfBoxNo="{ record }">
            <a-input v-model:value="record.selfBoxNo" style="width: 250px;" :maxlength="35" :placeholder="$t('common.optional')" v-box-no-filter allow-clear></a-input>
          </template>
          <template #expandIcon="{ expanded, record }">
            <CHaveProductInformation :open="expanded" @click="handleOpenOrCloseRow(record)" />
          </template>
          <template #encasementSize="{ record }">
            <a-row type="flex" align="middle" :gutter="8">
              <a-col>
                <a-input-number
                  v-model:value="record.containerLength"
                  :min="1"
                  :max="99999"
                  :precision="0"
                  :placeholder="$t('common.max_length_side')"
                ></a-input-number>
              </a-col>
              <a-col>x</a-col>
              <a-col>
                <a-input-number
                  v-model:value="record.containerWidth"
                  :min="1"
                  :max="99999"
                  :precision="0"
                  :placeholder="$t('common.second_length_side')"
                ></a-input-number>
              </a-col>
              <a-col>x</a-col>
              <a-col>
                <a-input-number
                  v-model:value="record.containerHeight"
                  :min="1"
                  :max="99999"
                  :precision="0"
                  :placeholder="$t('common.least_length_side')"
                ></a-input-number>
              </a-col>
              <a-col>cm</a-col>
            </a-row>
          </template>
          <template #encasementWeight="{ record }">
            <a-input-number
              v-model:value="record.containerWeight"
              :min="0.001"
              :max="999999.999"
              :precision="3"
            ></a-input-number> kg
          </template>
          <template #containerCount="{ record }">
            {{ getBoxProductCount(record) }} Unit
          </template>
          <template #operation="{ record, index }">
            <a-row :gutter="[8, 8]">
              <a-col>
                <a-button type="primary" ghost @click="handleOpenProductModal(record)">{{ $t('warehouse.add_product') }}</a-button>
              </a-col>
              <a-col>
                <a-button type="ghost" danger @click="handleRemoveBox(index)">{{ $t('common.remove') }}</a-button>
              </a-col>
            </a-row>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.items"
              :pagination="false"
              :row-key="(record) => record.id"
              size="small"
            >
              <template #productInfo="{ record }">
                <a-row :gutter="8" type="flex" align="middle">
                  <a-col>
                    <div class="table-list-img-common">
                      <c-image
                        :src="record.imgUrl"
                        :thumbWidth="600"
                        :thumbHeight="600"
                      />
                    </div>
                  </a-col>
                  <a-col flex="1">
                    <div>{{ record.productName }}</div>
                    <div>{{ record.productNo }}</div>
                  </a-col>
                </a-row>
              </template>
              <template #containerCount="{ record }">
                <a-input-number
                  v-model:value="record.containerCount"
                  :min="1"
                  :max="9999"
                  :precision="0"
                ></a-input-number> Unit
              </template>
              <template #operation="{ record, index }">
                <a-button type="ghost" danger @click="handleRemoveBoxProduct(record.parentRowKey, index)">{{ $t('common.remove') }}</a-button>
              </template>
            </a-table>
          </template>
        </a-table>
        <Address
          ref="refAddress"
          :addressData="initAddress"
          :isShowSelectCommonAddress="true"
        ></Address>
        <a-row type="flex" justify="end">
          <a-col>
            <a-button class="mr-5 mb-3" type="primary" :loading="confirmLoading" @click="handleConfirm">{{ $t('warehouse.created_plan') }}</a-button>
          </a-col>
        </a-row>
      </a-spin>
      <ProductListModal ref="productListModalRef" @confirm="handleSetProduct" />
      <ImportProductModal ref="importProductModalRef" @confirm="handleSetBoxList" />
    </template>
  </Content>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { Button, Col, Form, Input, InputNumber, Row, Select, Spin, Table, Tag, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CImage from '@/views/components/CImage.vue';
import Address from '@/views/components/Address.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import StepsRow from './components/StepsRow.vue';
import ProductListModal from './components/ProductListModal.vue';
import ImportProductModal from './components/ImportProductModal.vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { getWarehouses } from '@/api/modules/common/index.js';
import { getCheckResult } from "@/api/modules/storage/index";
import { createInPlan, updateInPlan, getFirstStep } from "@/api/modules/consignment/inbound.js";
import { warehouseType as warehouseTypeEnum } from '@/enum/enum.json';
import { getAddressByLanguageV2, kgToG, gToKg, randomString, setFormStateValue, getLinkman } from '@/utils/general.js';
import { useTab } from "@/hooks/tabs/index";

export default defineComponent({
  name: 'storage_inbound_create',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATag: Tag,
    AButton: Button,
    ATable: Table,
    Content,
    CImage,
    Address,
    CHaveProductInformation,
    StepsRow,
    ProductListModal,
    ImportProductModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();

    const formRef = ref(null);
    const refAddress = ref(null);
    const productListModalRef = ref(null);
    const importProductModalRef = ref(null);

    const state = reactive({
      loading: false,
      confirmLoading: false,
      warehouseLoading: false,
      warehouseList: [],
      planId: null,
      initAddress: null,
      formState: {
        planName: null,
        from: {
          id: null,
          name: null,
          countryId: null,
          provinceId: null,
          cityId: null,
          countyId: null,
          address: null,
          address2: null,
          postCode: null
        },
        to: {
          warehouseType: warehouseTypeEnum.storageWarehouse,
          warehouseId: null,
        }
      },
      showWarehouseTag: false,
      boxList: [],
      boxExpandedRowKeysList: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        hideOnSinglePage: true,
        showSizeChanger: false,
        size: 'small',
        position: 'both',
      },
    });

    const checkPlanName = (rule, value) => {
      if (!value) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.in_plan_name')]));
      }
      // let reg = /^$|^[0-9A-Za-z\-\ ]{1,64}$/;
      // if (!reg.test(value)) {
      //   return Promise.reject(vueI18n.t('common.p0_format_error', [vueI18n.t('warehouse.in_plan_name')]));
      // }
      return Promise.resolve();
    }

    const rules = {
      planName: [
        {
          required: true,
          validator: checkPlanName,
        },
      ],
      to: {
        warehouseId: {
          required: true,
          message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.inbound_target_warehouse')]),
        }
      }
    }

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber"
        }
      },
      {
        width: 250,
        title: () => vueI18n.t("common.user_defined_box_no"),
        slots: {
          customRender: "selfBoxNo"
        }
      },
      { width: 50, },
      {
        width: 400,
        title: () => vueI18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 250,
        title: () => vueI18n.t("common.operation"),
        slots: {
          customRender: "operation"
        }
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 350,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
       {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 150,
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        slots: {
          customRender: "operation"
        }
      },
    ];

    const getSelectWarehouseAddress = computed(() => {
      let warehouseId = state.formState.to.warehouseId;
      if (warehouseId) {
        let warehouseInfo = state.warehouseList.find(item => item.id === warehouseId);
        if (warehouseInfo) {
          return getAddressByLanguageV2(warehouseInfo, getters.language);
        }
      }
      return '-';
    });

    const linkMan = computed(() => {
      let warehouseId = state.formState.to.warehouseId;
      if (warehouseId) {
        let warehouseInfo = state.warehouseList.find(item => item.id === warehouseId);
        if (warehouseInfo) {
          return getLinkman(warehouseInfo);
        }
      }
      return '-';
    })

    // 验证箱数据并返回需提交的箱列表
    const validateBoxData = () => {
      if (!state.boxList.length) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.box_list')]));
        return false;
      }

      let list = state.boxList.map(item => {
        let items = item.items.map(ite => ({
          productId: ite.id,
          containerCount: ite.containerCount,
        }));

        let sortArray = [item.containerLength, item.containerWidth, item.containerHeight].sort((a, b) => b - a);

        return {
          containerLength: sortArray[0],
          containerWidth: sortArray[1],
          containerHeight: sortArray[2],
          containerWeight: kgToG(item.containerWeight),
          selfBoxNo: item.selfBoxNo,
          items,
        }
      });

      let result = true;
      let msg = '';
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        // 自定义箱号可以为空
        // if (!item.selfBoxNo) {
        //   result = false;
        //   msg = vueI18n.t('common.p0_is_required', [vueI18n.t('common.user_defined_box_no')]);
        //   break;
        // }
        let reg = /^$|^[0-9A-Za-z\-\\\/\\_]{1,35}$/;
        if (!reg.test(item.selfBoxNo)) {
          result = false;
          msg = vueI18n.t('common.p0_format_error', [vueI18n.t('common.user_defined_box_no')]);
          break;
        }
        if (!item.containerLength || !item.containerWidth || !item.containerHeight) {
          result = false;
          msg = vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.encasement_size')]);
          break;
        }
        if (!item.containerWeight) {
          result = false;
          msg = vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.encasement_weight')]);
          break;
        }
        if (item.items.length) {
          for (let j = 0; j < item.items.length; j++) {
            const product = item.items[j];
            if (!product.containerCount) {
              result = false;
              msg = vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.product_quantity')]);
              break;
            }
          }
        }
      }

      if (!result) {
        message.error(msg);
        return false;
      }

      return list;
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        let items = validateBoxData();
        if (!items) {
          return;
        }
        
        await refAddress.value.refForm.validate();
        const data = Object.assign({}, state.formState, { items });
        let addressData = refAddress.value.data;
        data.from = {
          id: addressData.selectedCommonAddress,
          name: addressData.commonAddressName,
          countryId: addressData.selectedCountrys,
          provinceId: addressData.selectedProvinces,
          cityId: addressData.selectedCity,
          countyId: addressData.selectedCounty,
          address: addressData.detailedAddress,
          address2: addressData.detailedAddress2,
          postCode: addressData.postCode
        }
        state.confirmLoading = true;
        let planId = '';
        if (state.planId) {
          data.planId = state.planId;
          await updateInPlan(data);
          planId = state.planId;
        } else {
          let { result } = await createInPlan(data);
          planId = result;
        }
        message.success(vueI18n.t('common.succeed'));
        delVisitedRoute(router.currentRoute.value);
        router.push('/storage/inbound/confirm/' + planId);
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const getBoxProductCount = (record) => {
      if (!record.items) {
        return 0;
      }
      return record.items.reduce((total, item) => {
        if (item.containerCount && item.containerCount > 0) {
          return total += item.containerCount;
        }
      }, 0);
    };

    const handleWarehouseChange = () => {
      if (!state.formState.to.warehouseId) {
        state.showWarehouseTag = false;
        return
      }
      const data = {
        warehouseId: state.formState.to.warehouseId,
        warehouseType: warehouseTypeEnum.consignmentWarehouse,
      };
      state.warehouseLoading = true;
      getCheckResult(data).then(({ result }) => {
        if (result) {
          state.showWarehouseTag = true;
        } else {
          state.showWarehouseTag = false;
        }
      }).catch(() => {}).finally(() => {
        state.warehouseLoading = false;
      });
    }

    // 设置全部行展开
    const handleSetAllExpandedRows = () => {
      state.boxExpandedRowKeysList = state.boxList.map(item => item.rowKey);
    }

    // 展开关闭行
    const handleOpenOrCloseRow = (record, keepOpen = false) => {
      let rowKey = record.rowKey;
      if (state.boxExpandedRowKeysList.includes(rowKey)) {
        if (!keepOpen) {
          state.boxExpandedRowKeysList = state.boxExpandedRowKeysList.filter(item => item !== rowKey);
        }
      } else {
        state.boxExpandedRowKeysList.push(rowKey);
      }
    }

    // 设置箱
    const handleSetBoxList = (list) => {
      state.boxList = list.map(item => {
        item.items.forEach((product) => {
          product.parentRowKey = item.rowKey;
        });
        return item;
      });
      handleSetAllExpandedRows();
    }

    // 设置产品
    const handleSetProduct = ({ list, record, needBox }) => {
      if (needBox) {
        list.forEach(item => {
          if (!('containerCount' in item)) {
            item.containerCount = null;
          }
          for (let i = 0; i < item.boxCount; i++) {
            handleAddBox();
            let boxInfo = state.boxList[state.boxList.length - 1];
            item = JSON.parse(JSON.stringify(item));
            item.parentRowKey = boxInfo.rowKey;
            // 设置箱尺寸
            boxInfo.containerLength = item.containerLength;
            boxInfo.containerWidth = item.containerWidth;
            boxInfo.containerHeight = item.containerHeight;
            boxInfo.containerWeight = gToKg(item.containerWeight);

            boxInfo.items.push(item);
          }
        });
        handleSetAllExpandedRows();
      } else {
        let item = state.boxList.find(item => item.rowKey === record.rowKey);
        item.items = list.map(item => {
          if (!('containerCount' in item)) {
            item.containerCount = null;
          }
          item.parentRowKey = record.rowKey;
          return item;
        });
        handleOpenOrCloseRow(record, true);
      }
    }

    // 打开按产品添加弹窗
    const handleOpenProductModal = (record = null, needBox = false) => {
      productListModalRef.value.open(record, needBox);
    }

    // 打开快速导入弹窗
    const handleOpenImportModal = () => {
      importProductModalRef.value.open();
    }

    // 添加箱
    const handleAddBox = () => {
      const row = {
        rowKey: randomString(12),
        selfBoxNo: null,
        containerLength: null,
        containerWidth: null,
        containerHeight: null,
        containerWeight: null,
        inWarehouseBoxCount: null,
        items: [],
      }
      state.boxList.push(row);
    }

    // 移除箱
    const handleRemoveBox = (index) => {
      state.boxList.splice(index, 1);
    }

    // 移除箱产品
    const handleRemoveBoxProduct = (parentRowKey, index) => {
      let boxItem = state.boxList.find(item => item.rowKey === parentRowKey);
      if (boxItem) {
        boxItem.items.splice(index, 1);
      }
    }

    const getWarehouseList = () => {
      state.warehouseLoading = true;
      const data = {
        warehouseType: state.formState.to.warehouseType,
      }
      getWarehouses(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result;
        } else {
          state.warehouseList = [];
        }
      }).catch(() => {}).finally(() => {
        state.warehouseLoading = false;
      });
    }

    const getDetail = () => {
      state.loading = true;
      const data = {
        planId: state.planId,
        warehouseType: state.formState.to.warehouseType,
      }
      getFirstStep(data).then(({ result }) => {
        if (result) {
          setFormStateValue(state.formState, result);
          state.formState.to.warehouseId = result.to.id;
          state.boxList = result.items.map(item => {
            item.rowKey = randomString(12);
            item.containerWeight = gToKg(item.containerWeight);
            item.items = item.items.map(ite => {
              ite.id = ite.productId;
              ite.imgUrl = ite.productImgUrl;
              ite.parentRowKey = item.rowKey;
              return ite;
            });
            return item;
          });
          state.initAddress = {
            selectedCommonAddress: result.from.id,
            commonAddressName: result.from.linkMan,
            selectedCountrys: result.from.countryId,
            selectedProvinces: result.from.provinceId,
            selectedCity: result.from.cityId,
            selectedCounty: result.from.countyId,
            detailedAddress: result.from.address,
            detailedAddress2: result.from.address2,
            postCode: result.from.postCode
          }
        }
      }).catch((err) => {
      }).finally(() => {
        state.loading = false;
      });
    }

    onMounted(() => {
      getWarehouseList();
      let params = route.params;
      if ('id' in params && params.id) {
        state.planId = params.id;
        getDetail(params.id);
      }
    });

    return {
      ...toRefs(state),
      warehouseTypeEnum,
      formRef,
      refAddress,
      productListModalRef,
      importProductModalRef,
      rules,
      columns,
      innerColumns,
      getSelectWarehouseAddress,
      linkMan,
      handleConfirm,
      getBoxProductCount,
      handleOpenOrCloseRow,
      handleWarehouseChange,
      handleSetBoxList,
      handleSetProduct,
      handleOpenProductModal,
      handleOpenImportModal,
      handleAddBox,
      handleRemoveBox,
      handleRemoveBoxProduct,
    }
  }
})
</script>

<style scoped>

</style>